import React, { useRef, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import rhRoundel from '../assets/images/landing/joint-tickets/rh-top-roundel.png'
import bhRoundel from '../assets/images/landing/joint-tickets/bh-bottom-roundel.png'
import rhMain from '../assets/images/landing/joint-tickets/rh-main-4.jpg'
import bhMain from '../assets/images/landing/joint-tickets/bg-main-3.jpg'
import ticket from '../assets/images/landing/joint-tickets/ticket.png'
import rhLogo from '../assets/images/landing/joint-tickets/rh-logo.png'
import bgLogo from '../assets/images/landing/joint-tickets/bg-logo.png'
import rhFalconry from '../assets/images/landing/joint-tickets/falconry-2023.png'
import rhJungleHeights from '../assets/images/landing/joint-tickets/rh-jungleheights.png'
import rhToboggan from '../assets/images/landing/joint-tickets/rh-toboggan.png'
import rhFamilyRides from '../assets/images/landing/joint-tickets/rh-familyrides.png'
import bgRestrictedArea5 from '../assets/images/landing/joint-tickets/bg-restrictedarea5.png'
import bgExtinction from '../assets/images/landing/joint-tickets/bg-extinction.png'
import bgLoil from '../assets/images/landing/joint-tickets/loil.png'
import rhWonderWoods from '../assets/images/landing/joint-tickets/wonderwoods.png'
import bgSummerfest from '../assets/images/landing/joint-tickets/summerfest.png'
import arrowButton from '../assets/images/landing/joint-tickets/right-arrow-in-circular-button.png'

const BlackgangInfo = ({handleButton}) => {
    return (
        <div className="position-relative h-100" style={{paddingTop:'58px', paddingBottom:'72px'}}>
            <div className="position-absolute d-flex align-items-center w-100 top-0">
                <span className="flex-grow-1 text-light ms-4 fs-5 fw-bold">Explore Blackgang Chine</span>
                <button
                    className="btn btn-close bg-secondary py-3 d-xl-none "
                    data-bs-target="#landingCarousel" data-bs-slide-to="1" aria-current="true" aria-label="Slide 1"
                ></button>
                <button onClick={() => handleButton(2)}
                        className="btn btn-close bg-secondary py-3 d-none d-xl-block"
                        data-bs-target="#landingCarousel" data-bs-slide-to="1" aria-current="true" aria-label="Slide 1"
                ></button>
            </div>

            <div className="position-absolute bottom-0 d-grid w-100 p-2">
                <a href="/book-now-coming-soon/" className="btn btn-warning m-0 border-0 text-dark">BOOK NOW AND SAVE!</a>
            </div>
            

            <div className="overflow-scroll h-100 p-4 justify-content-center">
                <div className="d-flex">
                    <div>
                        <img src={bgLoil} className="me-3"/>
                    </div>
                    <div>
                        <h3 className="text-white text-uppercase">Land of Imagination Live - Mon - Thurs</h3>
                        <p className="text-white fs-7 mb-0">
                            Explore the fantastical themed areas as they host epic live shows, character meet and greets and an array interactive activities.
                            From fairies and cowboys, to pirates and dinosaurs, there really is something for everyone
                        </p>
                    </div>
                </div>
                <div className="my-3" style={{borderBottom:'1px solid #FFED22'}}></div>
                <div className="d-flex">
                    <div>
                        <img src={bgExtinction} className="me-3"/>
                    </div>
                    <div>
                        <h3 className="text-white text-uppercase">Extinction - New for 2023</h3>
                        <p className="text-white fs-7 mb-0">
                            A NEW adrenaline-inducing 360 pendulum swing that gives hair raising thrills, and breathtaking views… if you can keep your eyes open that is!
                        </p>
                    </div>
                </div>
                <div className="my-3" style={{borderBottom:'1px solid #FFED22'}}></div>
                <div className="d-flex">
                    <div>
                        <img src={bgSummerfest} className="me-3"/>
                    </div>
                    <div>
                        <h3 className="text-white text-uppercase">Summer Fest - Wednesday Nights 26 July - 30 Aug</h3>
                        <p className="text-white fs-7 mb-0">
                            Featuring the iconic family-favourite foam party, go crazy as our house DJ pumps up the
                            tunes creating the ultimate party vibes! Finish the night with a BANG at our fireworks finale!
                        </p>
                    </div>
                </div>
                <div className="my-3" style={{borderBottom:'1px solid #FFED22'}}></div>
                <div className="d-flex">
                    <div>
                        <img src={bgRestrictedArea5} className="me-3"/>
                    </div>
                    <div>
                        <h3 className="text-white text-uppercase">Restricted Area 5</h3>
                        <p className="text-white fs-7 mb-0">
                            Keep your wits about you and watch your step, there are prehistoric beasts around every corner!
                            Encounter life-size moving dinosaurs in the most realistic Jurassic experience since time began.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const RobinHillInfo = ({handleButton}) => {
    return (
        <div className="position-relative h-100" style={{paddingTop:'58px', paddingBottom:'72px'}}>
            <div className="position-absolute d-flex align-items-center w-100 top-0">
                <button 
                    className="btn btn-close bg-secondary py-3 d-xl-none "
                    data-bs-target="#landingCarousel" data-bs-slide-to="1" aria-current="true" aria-label="Slide 1"
                ></button>
                <button onClick={() => handleButton(0)}
                    className="btn btn-close bg-secondary py-3 d-none d-xl-block"
                    data-bs-target="#landingCarousel" data-bs-slide-to="1" aria-current="true" aria-label="Slide 1"
                ></button>
                <span className="flex-grow-1 text-light ms-4 fs-5 fw-bold">Explore Robin Hill</span>
            </div>

            <div className="position-absolute bottom-0 d-grid w-100 p-2">
                <a href="http://vectisventures.byretail.net/store/50" className="btn btn-warning m-0 border-0 text-dark">BOOK NOW AND SAVE!</a>
            </div>

            <div className="overflow-scroll h-100 p-4 justify-content-center">
                <div className="d-flex">
                    <div>
                        <img src={rhWonderWoods} className="me-3"/>
                    </div>
                    <div>
                        <h3 className="text-white text-uppercase">WonderWoods</h3>
                        <p className="text-white fs-7 mb-0">
                            Step into a realm where nature comes alive with colour for a whole day of festival fun nestled in the ancient woodlands.
                            Embark on an unforgettable adventure as you enter WonderWoods!
                            Plus there’s Tuesday evening spectacular with the Circus of Curiosities and kids rave!

                        </p>
                    </div>
                </div>
                <div className="my-3" style={{borderBottom:'1px solid #9FC03C'}}></div>
                <div className="d-flex">
                    <div>
                        <img src={rhToboggan} className="me-3"/>
                    </div>
                    <div>
                        <h3 className="text-white text-uppercase">Toboggan Run & Family Rides</h3>
                        <p className="text-white fs-7 mb-0">
                            Twist and turn on an exhilarating quarter mile downhill toboggan run.
                            Wobble across our sensory water pillow, swing high on the pirate ship, and see new worlds in the 4D cinema!
                        </p>
                    </div>
                </div>
                <div className="my-3" style={{borderBottom:'1px solid #9FC03C'}}></div>
                <div className="d-flex">
                    <div>
                        <img src={rhFalconry} className="me-3"/>
                    </div>
                    <div>
                        <h3 className="text-white text-uppercase">Falconry</h3>
                        <p className="text-white fs-7 mb-0">
                            Met our feathered friends in the islands most diverse Falconry Centre, with daily arena displays allowing you to get up
                            close and personal with a stunning array of birds of prey.
                        </p>
                    </div>
                </div>
                <div className="my-3" style={{borderBottom:'1px solid #9FC03C'}}></div>
                <div className="d-flex">
                    <div>
                        <img src={rhJungleHeights} className="me-3"/>
                    </div>
                    <div>
                        <h3 className="text-white text-uppercase">Adventure Play</h3>
                        <p className="text-white fs-7 mb-0">
                            Unleash your inner adventurer as you explore our bouncy sky nets, African Adventure playground, and journey through our Tree Top Trail.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const JointTickets = () => {
    const carouselRef = useRef()
    const [activeItem, setActiveItem] = useState(1)
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        if(carouselRef && carouselRef.current) {
            carouselRef.current.addEventListener('slide.bs.carousel', function(event) {
                setActiveItem(event.to)
            });
        }
    }, [])

    const handleButton = (position) => {
        let newOffset = 0;

        switch(position) {
            case 0:
                newOffset = '30';
                break;
            case 2:
                newOffset = '-30';
                break;
            case 1:
            default:
                newOffset = 0;
                break;
        }

        if(offset === newOffset) {
            newOffset = 0;
        }

        setOffset(newOffset);
    }

    return (
        <>
            <Helmet>
                <title>Blackgang Chine & Robin Hill Joint Park Tickets</title>
            </Helmet>
            
            {/* Mobile Version */}
            <div className="d-xl-none overflow-hidden">
                <div id="landingCarousel" ref={carouselRef} className="carousel slide position-absolute w-100 h-100 overflow-hidden d-flex justify-content-center" data-bs-wrap={false}>
                    {activeItem === 1 && 
                        <>
                            <div className="position-absolute" style={{zIndex:1000, top: '-5%'}}>
                                <img src={rhLogo} className="position-absolute start-50 translate-middle img-fluid" style={{maxWidth:'37%', zIndex:100, bottom: '16%'}} />
                                <img src={rhRoundel} className="img-fluid" style={{ transformOrigin: 'bottom', opacity: '0.7'}} />
                                <button className="btn-jointpark-rh mobile position-absolute start-50 translate-middle" type="button" data-bs-target="#landingCarousel" 
                                    data-bs-slide-to="0" aria-current="true" aria-label="Slide 1" style={{bottom: '-10px'}}>Find out more here</button>
                            </div>
                            <div className="position-absolute" style={{zIndex:1000 , bottom: '-5%'}}>
                                <img src={bgLogo} className="position-absolute start-50  translate-middle img-fluid" style={{maxWidth:'70%', zIndex:100, top:'20%'}} />
                                <img src={bhRoundel} className="img-fluid" style={{ transformOrigin: 'top', opacity: '0.7'}} />
                                <button className="btn-jointpark-bg mobile position-absolute start-50 translate-middle" type="button" data-bs-target="#landingCarousel" 
                                    data-bs-slide-to="2" aria-current="true" aria-label="Slide 1" style={{zIndex:1100, bottom: '16%'}}>Find out more here</button>
                            </div>
                        </>
                    }

                    <div className="carousel-inner h-100">
                        <div className="carousel-item h-100 bg-robinhill-grad">
                            <div className="overflow-scroll h-100">
                                <RobinHillInfo handleButton={handleButton}/>
                            </div>
                        </div>
                        <div className="carousel-item active position-relative h-100">
                            <div className="flex flex-col h-100">
                                <div className="h-50" style={{ backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage:`url(${rhMain})`}}>

                                </div>
                                <div className="h-50" style={{backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage:`url(${bhMain})`}}>
                                    
                                </div>
                                <a  href="/book-now-coming-soon/" target="_blank">
                                <div className="ticket position-absolute top-50 start-50 w-100 translate-middle d-flex justify-content-center">
                            
                                    <div className="position-absolute h-100 d-flex flex-column justify-content-center text-center" style={{marginTop:'-4px',zIndex:100,transform:'rotate(-4deg)', maxWidth: '300px'}}>
                                  
                                        <div style={{ padding:'3.6rem', minWidth: '300px'}}>
                                            <h2 className="text-black text-uppercase mb-0 fs-6">MEGA VALUE WITH A JOINT PARK TICKET</h2>
                                            <p className="my-1 mx-4 fs-6" style={{lineHeight: '1.1rem'}}>
                                                2 parks, 7 days<br/>
                                                Unlimited fun!
                                            </p>
                                            <div className="position-absolute" style={{bottom: '16%', left: '16%'}} >
                                                <span className="btn-book-now m-0 text-decoration-none" style={{fontSize: '14px'}}>BOOK NOW AND SAVE!</span>
                                            </div>
                                        </div>
                                     
                                    </div>
                                    <img src={ticket} className="img-fluid" style={{maxWidth: '350px'}}/>
                                </div>
                                </a>
                            </div>
                        </div>
                        <div className="carousel-item h-100 bg-blackgangchine-grad">
                            <BlackgangInfo handleButton={handleButton} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-none d-xl-block position-absolute w-100 h-100 overflow-hidden scrollbar-hidden">
                <div className="h-100 bg-blackgangchine-grad d-flex align-items-center" style={{width:"30vw", position:"absolute", right:"0"}}>
                    <div className="h-100 overflow-scroll">
                        <BlackgangInfo handleButton={handleButton} />
                    </div>
                    
                </div>
                <div className="h-100 bg-robinhill-grad d-flex align-items-center" style={{width:"30vw", position:"absolute", left:"0"}}>
                    <div className="h-100 overflow-scroll">
                        <RobinHillInfo handleButton={handleButton}/>
                    </div>
                </div>
                <div className="transition-slide position-absolute top-0 w-100 h-100 overflow-hidden shadow-lg" style={{marginLeft:`${offset}vw`}}>
                    <div className="position-absolute top-50 start-50 translate-middle" style={{zIndex:100}}>
                        <img src={ticket} />
                        <div className="position-absolute top-0 bottom-0 w-100"  style={{ zIndex:100,transform:'rotate(-4deg)'}}>
                            <div className="d-flex flex-column justify-content-center align-items-center h-100" style={{marginTop:'-4px'}}>
                                <h2 className="text-black text-uppercase text-center mb-0 fs-4">MEGA VALUE WITH A <br/>JOINT PARK TICKET</h2>
                                <p className="my-1 mx-4 fs-5 text-center">
                                    2 parks, 7 days<br/>
                                    Unlimited fun!
                                </p>
                                <a href="/book-now-coming-soon/" className="text-dark fs-4 text-decoration-none">BOOK NOW AND SAVE!</a>
                            </div>
                        </div>
                    </div>

                    <div className="h-100 row position-relative m-0" style={{maxWidth:"100vw"}}>
                         <div className="col d-flex h-100 align-items-start justify-content-center" style={{ backgroundSize: 'cover', backgroundPosition: 'top', backgroundImage:`url(${rhMain})`}}>
                            <div className="position-relative">
                                <img src={rhLogo} className="position-absolute start-50 top-25 translate-middle img-fluid" style={{maxWidth:'50%', zIndex:100}} />
                                <img src={rhRoundel} className="img-fluid mx-auto" style={{ transformOrigin: 'bottom'}} />
                                <button className="btn-jointpark-rh mobile position-absolute start-50 translate-middle" type="button" data-bs-target="#landingCarousel" 
                                data-bs-slide-to="0" aria-current="true" aria-label="Slide 1" style={{bottom: '-10px'}} onClick={() => handleButton(0)}>Find out more here</button>

                            </div>
                        </div>
                        <div className="col d-flex h-100 align-items-end justify-content-center" style={{backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage:`url(${bhMain})`}}>
                            <div className="position-relative">
                                <img src={bgLogo} className="position-absolute start-50 top-25 translate-middle img-fluid" style={{maxWidth:'70%', zIndex:100}} />
                                <img src={bhRoundel} className="img-fluid" style={{ transformOrigin: 'top'}} />
                                <button className="btn-jointpark-bg mobile position-absolute start-50 translate-middle" type="button" data-bs-target="#landingCarousel" 
                                data-bs-slide-to="2" aria-current="true" aria-label="Slide 1" style={{zIndex:1100, bottom: '-1px'}} onClick={() => handleButton(2)}>Find out more here</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default JointTickets